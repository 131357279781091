<template>
  <div>
    <FormulateForm #default="{ hasErrors }" class="min-h-full flex-1">
      <div class="flex flex-col justify-between min-h-full">
        <div class="form flex w-full flex-col flex-1">
          <FormulateInput
            class="w-full"
            label="Field Name"
            :disabled="isFieldSystemProvided || queryData === 'view'"
            v-bind:style="{
              opacity: queryData !== 'view' ? 1 : 1,
              cursor: isFieldSystemProvided
                ? 'not-allowed'
                : queryData !== 'view'
                ? 'pointer'
                : 'not-allowed',
            }"
            :element-class="
              (context, classes) => {
                const additionalClasses =
                  queryData !== 'view' && !isFieldSystemProvided
                    ? 'opacity-100'
                    : 'opacity-50';
                return [`flex-1 min-w-full ${additionalClasses}`].concat(
                  classes
                );
              }
            "
            placeholder="Field Name"
            type="text"
            validation="required"
            v-model="fieldData.name"
          />
          <FormulateInput
            class="w-full"
            label="Field Label"
            :disabled="queryData === 'view'"
            :element-class="
              (context, classes) => {
                const additionalClasses =
                  queryData !== 'view' ? 'opacity-100' : 'opacity-50';
                return [`flex-1 min-w-full ${additionalClasses}`].concat(
                  classes
                );
              }
            "
            v-bind:style="{
              opacity: queryData !== 'view' ? 1 : 1,
              cursor: queryData !== 'view' ? 'pointer' : 'not-allowed',
            }"
            placeholder="Field Label"
            type="text"
            validation="required"
            v-model="fieldData.label"
          />
          <FormulateInput
            v-model="entityTypesModel"
            @input="handleInput"
            class="w-full"
            :options="entityTypeOptions"
            variant="alt"
            label="Entity Type"
            :disabled="
              queryData === 'view' ||
                isClonedSystemProvided ||
                isFieldSystemProvided
            "
            placeholder="Select Entity Type"
            type="multi-select"
            validation="required"
            v-bind:style="{
              opacity: queryData !== 'view' ? 1 : 1,
              cursor:
                isFieldSystemProvided || isClonedSystemProvided
                  ? 'not-allowed'
                  : queryData !== 'view'
                  ? 'pointer'
                  : 'not-allowed',
            }"
            :element-class="
              (context, classes) => {
                const additionalClasses =
                  queryData !== 'view' && !isClonedSystemProvided
                    ? 'opacity-100'
                    : 'opacity-50';
                return [`flex-1 min-w-full ${additionalClasses}`].concat(
                  classes
                );
              }
            "
            :config="{
              ...ENTITY_TYPES_DROPDOWN_CONFIG,
              limit: 4,
            }"
          />
          <FormulateInput
            class="w-full testClass"
            label="Info Text"
            placeholder="Info Text"
            type="rich-text"
            validation="required"
            :element-class="
              (context, classes) => ['flex-1 min-w-full'].concat(classes)
            "
            v-model="fieldData.placeholder"
          />
          <FormulateInput
            class="w-full"
            label="Description"
            :disabled="
              (fieldData && fieldData.is_restricted) || queryData == 'view'
            "
            :element-class="
              (context, classes) => ['flex-1 min-w-full'].concat(classes)
            "
            placeholder="Description"
            type="textarea"
            v-model="fieldData.description"
          />
        </div>

        <div class="card-actions justify-end" v-if="!fieldData.is_restricted">
          <Button
            text="Save"
            type="primary"
            :disabled="hasErrors"
            @click.native="$emit('saveCreateField')"
          />
        </div>
      </div>
    </FormulateForm>
  </div>
</template>

<style scoped></style>

<script>
const Button = () => import("@/components/button");
import { ENTITY_TYPES_DROPDOWN_CONFIG } from "@shared/utils/constants";

export default {
  name: "label-info-rich-text",
  props: [
    "fieldData",
    "queryData",
    "entityTypeOptions",
    "isClonedSystemProvided",
    "isFieldSystemProvided",
    "fieldOptions",
  ],
  components: {
    Button,
  },
  data() {
    return {
      ENTITY_TYPES_DROPDOWN_CONFIG,
    };
  },
  computed: {
    entityTypesModel: {
      get() {
        return this.fieldData.entity_types;
      },
      set(val) {
        if (this.fieldOptions.some((opt) => !!opt.dependents?.length)) {
          this.$toast.error(
            "Cannot update field’s entity type as it associated with a cascading field"
          );
        } else {
          this.fieldData.entity_types = val;
        }
      },
    },
  },
  methods: {
    handleInput() {
      this.$emit("handleInput");
    },
  },
};
</script>
